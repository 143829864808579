import { Box } from '@mui/material'
import ShoppingCartPhysicalLoyaltyVoucher
  from '~/src/components/cart/shopping-cart-loyalty-voucher/ShoppingCartPhysicalLoyaltyVoucher'
import ShoppingCartPhysicalLoyaltyVoucherTitle
  from '~/src/components/cart/shopping-cart-loyalty-voucher/ShoppingCartPhysicalLoyaltyVoucherTitle'
import { useEoValue } from '~/src/hooks/useEoState'
import { IS_LOGGED_IN_AS_SELECTOR_FAMILY } from '~/src/stores/authentication'
import { LOYALTY_IS_ENABLED_AND_CUSTOMER_IS_LOGGED_IN_SELECTOR } from '~/src/stores/loyalty'

const CheckoutPhysicalLoyaltyVoucherSection = () => {
  const loyaltyIsEnabled = useEoValue(LOYALTY_IS_ENABLED_AND_CUSTOMER_IS_LOGGED_IN_SELECTOR)
  const loggedInAsCustomer = useEoValue(IS_LOGGED_IN_AS_SELECTOR_FAMILY('customer'))

  if (!loggedInAsCustomer || loyaltyIsEnabled) {
    return null
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 2
    }}>
      <ShoppingCartPhysicalLoyaltyVoucherTitle/>

      <ShoppingCartPhysicalLoyaltyVoucher/>
    </Box>
  )
}

export default CheckoutPhysicalLoyaltyVoucherSection
