import { formHelperTextClasses, inputBaseClasses, inputClasses, TextField, TextFieldProps } from '@mui/material'
import { useTranslations } from '~/src/hooks/useTranslations'
import { useMemo } from 'react'
import { OverridableStringUnion } from '@mui/types'
import { TextFieldPropsColorOverrides } from '@mui/material/TextField/TextField'

interface ShoppingCartPhysicalLoyaltyVoucherTextFieldProps extends Omit<TextFieldProps, 'placeholder'> {
  success: boolean,
}

const ShoppingCartPhysicalLoyaltyVoucherTextField = (
  {
    sx = {},
    success,
    error,
    ...otherTextFieldProps
  }: ShoppingCartPhysicalLoyaltyVoucherTextFieldProps
) => {
  const { t } = useTranslations()

  const color = useMemo<OverridableStringUnion<'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning', TextFieldPropsColorOverrides>>(() => {
    if (error) {
      return 'error'
    } else if (success) {
      return 'success'
    }

    return 'container'
  }, [ error, success ])

  const helperText = useMemo<string | undefined>((): string | undefined => {
    if (success) {
      return 'Valid voucher code'
    } else if (error) {
      return 'Invalid voucher code'
    }
  }, [ error, success, t ])

  return (
    <TextField
      type='text'
      name='voucher'
      error={error}
      color={color}
      placeholder={t('order.voucherCode')}
      size='small'
      helperText={helperText}
      className={success ? 'Mui-success' : undefined}
      sx={{
        ...sx,
        [`.${inputBaseClasses.root}`]: {
          [`&.${inputClasses.error}`]: {
            input: {
              color: 'error.main'
            }
          }
        },
        ['&.Mui-success']: {
          [`.${formHelperTextClasses.root}`]: {
            color: 'success.main'
          },
          fieldset: {
            borderColor: 'success.main'
          }
        }
      }}
      {...otherTextFieldProps}
    />
  )
}

export default ShoppingCartPhysicalLoyaltyVoucherTextField
