import { useTranslations } from '~/src/hooks/useTranslations'
import Button, { ButtonProps } from '../../mui-wrappers/buttons/Button'

const ShoppingCartPhysicalLoyaltyVoucherAction = (props: Omit<ButtonProps, 'children'>) => {
  const { t } = useTranslations()

  return (
    <Button
      variant='contained'
      preventMultipleClick
      {...props}>
      {t('global.apply')}
    </Button>
  )
}

export default ShoppingCartPhysicalLoyaltyVoucherAction
