import { Box } from '@mui/material'
import ShoppingCartPhysicalLoyaltyVoucherTextField
  from '~/src/components/cart/shopping-cart-loyalty-voucher/ShoppingCartPhysicalLoyaltyVoucherTextField'
import ShoppingCartPhysicalLoyaltyVoucherAction
  from '~/src/components/cart/shopping-cart-loyalty-voucher/ShoppingCartPhysicalLoyaltyVoucherAction'
import usePhysicalVoucher from '~/src/hooks/loyalty/usePhysicalVoucher'
import { ChangeEvent, KeyboardEvent, useState } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'

const ShoppingCartPhysicalLoyaltyVoucher = () => {
  const checkoutFormState = useEoValue<CheckoutFormState>(CHECKOUT_FORM_STATE)
  const [ value, setValue ] = useState<string>(checkoutFormState.physicalVoucher?.barcode ?? '')
  const { use, reset, loading, success, error } = usePhysicalVoucher({
    success: !!checkoutFormState?.physicalVoucher
  })

  const isActionButtonDisabled = () => {
    return error || success || !value
  }

  const _handleOnApplyVoucher = () => {
    void use(value)
  }

  const _handleOnKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter' || isActionButtonDisabled()) {
      return
    }

    _handleOnApplyVoucher()
  }

  const _handleOnInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    reset()
    setValue(e.target.value)
  }

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'flex-start',
      gap: 2
    }}>
      <ShoppingCartPhysicalLoyaltyVoucherTextField
        value={value}
        defaultValue={value}
        error={error}
        success={success}
        disabled={loading}
        onChange={_handleOnInputChange}
        onKeyUp={_handleOnKeyUp}
        sx={{
          flex: '1 1 auto'
        }}/>

      <ShoppingCartPhysicalLoyaltyVoucherAction
        loading={loading}
        disabled={isActionButtonDisabled()}
        onClick={_handleOnApplyVoucher}
        sx={{
          flex: '0 1 125px',
          mt: '1px'
        }}/>
    </Box>
  )
}

export default ShoppingCartPhysicalLoyaltyVoucher
