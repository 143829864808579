import { useState } from 'react'
import { useVoucher } from '~/src/services/OrderService'
import CustomerLoyaltyVoucher from '@eo-storefronts/eo-core/dist/models/CustomerLoyaltyVoucher'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { useSetEoState } from '~/src/hooks/useEoState'

interface ReturnsType {
  use(value: string): Promise<CustomerLoyaltyVoucher | undefined>,
  reset(): void,
  loading: boolean,
  error: boolean,
  success: boolean,
}

interface UsePhysicalVoucherProps {
  error?: boolean,
  success?: boolean,
  loading?: boolean,
}

const usePhysicalVoucher = (props: UsePhysicalVoucherProps): ReturnsType => {
  const [ success, setSuccess ] = useState<boolean>(props?.success ?? false)
  const [ error, setError ] = useState<boolean>(props?.error ?? false)
  const [ loading, setLoading ] = useState<boolean>(props.loading ?? false)
  const setCheckoutFormState = useSetEoState<CheckoutFormState>(CHECKOUT_FORM_STATE)

  const use = async (value: string): Promise<CustomerLoyaltyVoucher | undefined> => {
    setError(false)
    setSuccess(false)
    setLoading(true)

    try {
      const response: CustomerLoyaltyVoucher = await useVoucher(value)

      setSuccess(true)
      setCheckoutFormState((state: CheckoutFormState) => ({
        ...state,
        physicalVoucher: response
      }))

      return response
    } catch {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  const reset = () => {
    setSuccess(false)
    setError(false)
    setLoading(false)
  }

  return {
    use,
    reset,
    loading,
    success,
    error
  }
}

export default usePhysicalVoucher
